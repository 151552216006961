import React from 'react';

const SocialPreview = () => {
  return (
    <div className="max-w-2xl mx-auto p-6">
      <h2 className="text-xl font-semibold mb-4">Social Media Preview</h2>
      
      {/* Facebook Preview */}
      <div className="mb-8 border border-gray-200 rounded-lg p-4">
        <div className="text-sm text-gray-600 mb-2">Facebook</div>
        <div className="bg-white shadow-sm rounded-lg overflow-hidden">
          <img 
            src="/twittercard.png" 
            alt="Social Preview" 
            className="w-full h-auto object-cover"
          />
          <div className="p-4">
            <div className="text-sm text-gray-500 mb-1">databackfill.com</div>
            <div className="font-semibold mb-1">DataBackfill - GA4 Data Sync Made Simple</div>
            <div className="text-sm text-gray-600">
            Backfill your historical GA4 data into BigQuery
            </div>
          </div>
        </div>
      </div>

      {/* Twitter Preview */}
      <div className="border border-gray-200 rounded-lg p-4">
        <div className="text-sm text-gray-600 mb-2">Twitter</div>
        <div className="bg-white shadow-sm rounded-lg overflow-hidden">
          <img 
            src="/twittercard.png" 
            alt="Social Preview" 
            className="w-full h-auto object-cover"
          />
          <div className="p-4">
            <div className="font-semibold mb-1">DataBackfill - GA4 Data Sync Made Simple</div>
            <div className="text-sm text-gray-600 mb-1">
              SBackfill your historical GA4 data into BigQuery
            </div>
            <div className="text-sm text-gray-500">databackfill.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialPreview;