import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp, ExternalLink, CheckCircle2, AlertCircle } from 'lucide-react';
import apiClient from '../../utils/apiClient';

const SettingsForm = ({paid}) => {
  // Original state variables - unchanged
  const [credentials, setCredentials] = useState({
    projectId: '',
    datasetId: '',
    tableId: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [trial, setTrial] = useState(0);

  // New state for checklist
  const [isChecklistExpanded, setIsChecklistExpanded] = useState(true);
  const [checklist, setChecklist] = useState({
    iam: false,
    bigquery: false,
    ga4: false,
    apis: false
  });
  const [lastCompleted, setLastCompleted] = useState(null);

  // Original handlers - unchanged
  const handleCredentialChange = (field, value) => {
    setCredentials(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await apiClient.post('/setup', {
        project_id: credentials.projectId,
        dataset_id: credentials.datasetId,
        table_id: credentials.tableId
      });

      if (response.redirect_url) {
        window.location.href = `${response.redirect_url}?tab=sync`;
      }
    } catch (error) {
      console.error('Setup error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Original trial period calculation - unchanged
  useEffect(() => {
    console.log('userEfect')
    const createdTime = localStorage.getItem('createdTime');
    const timestamp = createdTime
    const currentDate = new Date();
    const currentTimestamp = currentDate.getTime() / 1000;
    const differenceInSeconds = currentTimestamp - timestamp;
    const differenceInDays = Math.floor(differenceInSeconds / (60 * 60 * 24)); 
    console.log('DifferceL: ', differenceInDays);
    setTrial(differenceInDays<7 ? 7-differenceInDays : 0);
  }, []);

  useEffect(() => {
    // Load checklist from server when component mounts
    const loadChecklist = async () => {
      try {
        const response = await apiClient.get('/checklist-progress');
        if (response.checklist) {
          setChecklist(response.checklist);
          // Also update localStorage
          localStorage.setItem('setupChecklist', JSON.stringify(response.checklist));
        }
      } catch (error) {
        console.error('Error loading checklist:', error);
        // If server fails, we'll still have localStorage data
        const savedChecklist = localStorage.getItem('setupChecklist');
        if (savedChecklist) {
          setChecklist(JSON.parse(savedChecklist));
        }
      }
    };
  
    loadChecklist();
  }, []);

  // New checklist handler
  // Near the top of your file where other useEffect hooks are
useEffect(() => {
  // Your existing trial period calculation
  console.log('userEfect')
  const createdTime = localStorage.getItem('createdTime');
  const timestamp = createdTime
  const currentDate = new Date();
  const currentTimestamp = currentDate.getTime() / 1000;
  const differenceInSeconds = currentTimestamp - timestamp;
  const differenceInDays = Math.floor(differenceInSeconds / (60 * 60 * 24)); 
  console.log('DifferceL: ', differenceInDays);
  setTrial(differenceInDays<7 ? 7-differenceInDays : 0);
}, []);

// Add this new useEffect right after the first one
useEffect(() => {
  // Load checklist from server when component mounts
  const loadChecklist = async () => {
    try {
      const response = await apiClient.get('/checklist-progress');
      if (response.checklist) {
        setChecklist(response.checklist);
        // Also update localStorage
        localStorage.setItem('setupChecklist', JSON.stringify(response.checklist));
      }
    } catch (error) {
      console.error('Error loading checklist:', error);
      // If server fails, we'll still have localStorage data
      const savedChecklist = localStorage.getItem('setupChecklist');
      if (savedChecklist) {
        setChecklist(JSON.parse(savedChecklist));
      }
    }
  };

  loadChecklist();
}, []);

// Replace your existing handleChecklistItemChange with this version
const handleChecklistItemChange = (item) => {
  setChecklist(prev => {
    const newChecklist = {
      ...prev,
      [item]: !prev[item]
    };

    // Save to localStorage immediately
    localStorage.setItem('setupChecklist', JSON.stringify(newChecklist));
    
    // Save to server
    apiClient.post('/checklist-progress', { 
      checklist: newChecklist 
    }).catch(error => {
      console.error('Error saving checklist:', error);
    });
    
    setLastCompleted(newChecklist[item] ? item : null);
    return newChecklist;
  });
};

  // Calculate progress
  const completedCount = Object.values(checklist).filter(Boolean).length;
  const totalSteps = Object.keys(checklist).length;
  const progressPercentage = (completedCount / totalSteps) * 100;
  const allPrerequisitesComplete = Object.values(checklist).every(item => item);

  return (
    <div className="max-w-2xl mx-auto">
      <div className="flex items-center justify-between mb-6 md:mb-8">
        <div>
          <h1 className="text-xl md:text-2xl font-semibold text-gray-900">Google Cloud Settings</h1>
          <p className="mt-1 text-sm md:text-base text-gray-600">
            Configure your Google Cloud credentials to enable data synchronization
          </p>
        </div>
      </div>

      {/* New Checklist Section */}
      <div className="bg-blue-50 rounded-lg border border-blue-200 mb-6">
        <button
          onClick={() => setIsChecklistExpanded(!isChecklistExpanded)}
          className="w-full px-6 py-4 flex items-center justify-between text-blue-700 hover:bg-blue-100 transition-colors rounded-lg"
        >
          <div className="flex items-center space-x-2">
            {allPrerequisitesComplete ? (
              <CheckCircle2 className="w-5 h-5 text-green-500" />
            ) : (
              <AlertCircle className="w-5 h-5 text-blue-500" />
            )}
            <span className="font-medium">Prerequisites Checklist</span>
            {allPrerequisitesComplete && <span className="ml-2 text-sm text-green-600">All set! ✨</span>}
          </div>
          {isChecklistExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>

        {isChecklistExpanded && (
          <div className="px-6 pb-4">
            <div className="space-y-3">
              <div className="flex items-start space-x-3">
                <input
                  type="checkbox"
                  id="iam"
                  checked={checklist.iam}
                  onChange={() => handleChecklistItemChange('iam')}
                  className="mt-1 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <div className="flex-1">
                  <label htmlFor="iam" className="text-sm font-medium text-gray-700 cursor-pointer">
                    Configure IAM Roles
                  </label>
                  <p className="text-sm text-gray-600">
                    Add required BigQuery roles
                    <a href="/documentation#iam-roles" className="ml-2 text-blue-600 hover:text-blue-700 inline-flex items-center">
                      View Guide
                      <ExternalLink size={14} className="ml-1" />
                    </a>
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-3">
                <input
                  type="checkbox"
                  id="bigquery"
                  checked={checklist.bigquery}
                  onChange={() => handleChecklistItemChange('bigquery')}
                  className="mt-1 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <div className="flex-1">
                  <label htmlFor="bigquery" className="text-sm font-medium text-gray-700 cursor-pointer">
                    Create BigQuery Schema
                  </label>
                  <p className="text-sm text-gray-600">
                    Set up your BigQuery table
                    <a href="/documentation#bigquery-setup" className="ml-2 text-blue-600 hover:text-blue-700 inline-flex items-center">
                      View Guide
                      <ExternalLink size={14} className="ml-1" />
                    </a>
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-3">
                <input
                  type="checkbox"
                  id="ga4"
                  checked={checklist.ga4}
                  onChange={() => handleChecklistItemChange('ga4')}
                  className="mt-1 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <div className="flex-1">
                  <label htmlFor="ga4" className="text-sm font-medium text-gray-700 cursor-pointer">
                    Grant GA4 Editor Access
                  </label>
                  <p className="text-sm text-gray-600">
                    Assign Editor role in GA4
                    <a href="/documentation#ga4-setup" className="ml-2 text-blue-600 hover:text-blue-700 inline-flex items-center">
                      View Guide
                      <ExternalLink size={14} className="ml-1" />
                    </a>
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-3">
                <input
                  type="checkbox"
                  id="apis"
                  checked={checklist.apis}
                  onChange={() => handleChecklistItemChange('apis')}
                  className="mt-1 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <div className="flex-1">
                  <label htmlFor="apis" className="text-sm font-medium text-gray-700 cursor-pointer">
                    Enable Required APIs
                  </label>
                  <p className="text-sm text-gray-600">
                    Enable BigQuery and Analytics APIs
                    <a href="/documentation#cloud-apis" className="ml-2 text-blue-600 hover:text-blue-700 inline-flex items-center">
                      View Guide
                      <ExternalLink size={14} className="ml-1" />
                    </a>
                  </p>
                </div>
              </div>
            </div>

            {lastCompleted && (
              <div className="mt-4 p-3 bg-green-50 border border-green-100 rounded-lg">
                <div className="flex items-start">
                  <CheckCircle2 className="w-5 h-5 text-green-500 mt-0.5 mr-2" />
                  <p className="text-sm font-medium text-green-800">
                    {lastCompleted === 'iam' && "Great! Now set up your BigQuery schema for storing the GA4 data."}
                    {lastCompleted === 'bigquery' && "Perfect! Next, make sure you have Editor access to your GA4 property."}
                    {lastCompleted === 'ga4' && "Excellent! Last step - enable the required Google Cloud APIs."}
                    {lastCompleted === 'apis' && "Almost there! Just enter your credentials below to complete the setup."}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Original Form Section - unchanged */}
      <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
        <form onSubmit={handleSubmit} className="p-4 md:p-6 space-y-4 md:space-y-6">
          {[
            { label: 'Google Cloud Project ID *ex: ga4project*', key: 'projectId', type: 'text', placeholder: 'Enter your Google Cloud Project ID' },
            { label: 'BigQuery Dataset ID *ex: ga4data*', key: 'datasetId', type: 'text', placeholder: 'Enter your BigQuery Dataset ID' },
            { label: 'BigQuery Table ID *ex: testga4 (use table name only without the period)*', key: 'tableId', type: 'text', placeholder: 'Enter your BigQuery Table ID' }
          
          ].map((field) => (
            <div key={field.key}>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {field.label}
              </label>
              <input
                type={field.type}
                value={credentials[field.key]}
                onChange={(e) => handleCredentialChange(field.key, e.target.value)}
                className="w-full p-2 md:p-3 border border-gray-300 rounded-lg focus:ring-2 
                         focus:ring-blue-500 focus:border-blue-500 text-sm md:text-base"
                placeholder={field.placeholder}
                required
              />
            </div>
          ))}
          <div className="relative group">
            <button
              type="submit"
              disabled={isLoading || (!paid && !trial)}
              className={`w-full py-2 md:py-3 px-4 rounded-lg font-medium text-sm md:text-base
                       ${isLoading 
                         ? 'bg-gray-300 cursor-not-allowed'
                         : 'bg-blue-600 hover:bg-blue-700 text-white'
                       } transition-colors`}
            >
              {(!paid && !trial) ? 'Save Settings (Upgrade Required)' : (isLoading ? 'Saving...' : 'Save Settings')}
            </button>
            {!paid && (
              <div className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm py-1 px-2 rounded">
                Upgrade to save configurations
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SettingsForm;