import React, { useEffect, useState } from "react";
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import { Check, ArrowRight, Database, Lock, Shield } from "lucide-react";
import { useNavigate } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import apiClient from '../utils/apiClient';
import { toast } from 'react-toastify';
import axios from "axios";
import {BASE_URL} from "../config"

const stripePromise = loadStripe(
  "pk_live_51QL9nuBySApRicVUmFsIkWlf1K97iYbRWAIY49E0096xYc0TFj7UYtvuzscKG1rC61eSohsOs2h35wVbgeDhMxlt00T0h2wUZE"
  // "pk_test_51QL9nuBySApRicVUHHjJpQpg45hijjIZoPDJ6vaHwvnPXpSoluxtgUqmn28Y1Xufi0r6aKpJommjkn8k8e6lmtcW00oFkQBKtx"
);

const PricingPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('pricing', '');
    console.log('useremail', localStorage.getItem('databackfill-useremail'));
  }, [])

  const handleCheckout = async (plan) => {
    if(plan != "basic") {
      localStorage.setItem('pricing', 'professional');
      console.log('storage', localStorage.getItem('pricing'));
    }
    setLoading(true)
    const check = await apiClient.post(
      '/payment-check',
      {
        email: localStorage.getItem('databackfill-useremail')
      }
    );
    console.log(check);
    
    localStorage.setItem('pricing', '');
    if(check.paid=='paid') {
      toast.error('You already paid!');
      setLoading(false)
      return;
    }
    console.log("object");
    let value = "";
    if (plan === "basic") {
      navigate('/auth');
      return;
    } else {
      localStorage.setItem('pricing', 'professional');
      const response = await apiClient.post(
        `/api/create-checkout-session`,
        {
          email: localStorage.getItem('databackfill-useremail')
        }
      );
      console.log("🚀 ~ handleCheckout ~ data:", response);

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: response.sessionId });
    }
      setLoading(false);
  };
  return (
    loading ? (
    <div className="min-h-screen bg-white overflow-hidden">
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    </div>
  ) :
    <div className="min-h-screen bg-white overflow-hidden">
      <Header />
      {/* Pricing Section */}
      <section className="pt-20 md:pt-32 pb-12 md:pb-24">
       
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <div className="text-center mb-8 md:mb-16">
            <div className="inline-block px-4 py-1 bg-blue-50 rounded-full text-blue-600 font-medium text-sm mb-4">
              Simple One-Time Pricing
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Choose the perfect plan for your data needs
            </h2>
            <p className="text-base md:text-xl text-gray-600 max-w-2xl mx-auto">
              Simple, transparent pricing that grows with your analytics
              requirements
            </p>
          </div>
          {/* Security Trust Badges */}
<div className="max-w-3xl mx-auto mb-12 py-6">
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
    <div className="flex items-center justify-center bg-gray-50 rounded-lg p-4">
      <div className="w-10 h-10 bg-blue-50 rounded-lg flex items-center justify-center mr-3">
        <Database className="w-5 h-5 text-blue-600" />
      </div>
      <div className="text-sm">
        <p className="font-medium">Your BigQuery</p>
        <p className="text-gray-600">Data stays in your instance</p>
      </div>
    </div>
    
    <div className="flex items-center justify-center bg-gray-50 rounded-lg p-4">
      <div className="w-10 h-10 bg-blue-50 rounded-lg flex items-center justify-center mr-3">
        <Lock className="w-5 h-5 text-blue-600" />
      </div>
      <div className="text-sm">
        <p className="font-medium">Complete Control</p>
        <p className="text-gray-600">Manage access via IAM</p>
      </div>
    </div>
    
    <div className="flex items-center justify-center bg-gray-50 rounded-lg p-4">
      <div className="w-10 h-10 bg-blue-50 rounded-lg flex items-center justify-center mr-3">
        <Shield className="w-5 h-5 text-blue-600" />
      </div>
      <div className="text-sm">
        <p className="font-medium">Zero Data Storage</p>
        <p className="text-gray-600">We never store your data</p>
      </div>
    </div>
  </div>
</div>

          {/* Pricing Cards */}
          <div className="flex gap-8 justify-center flex-col sm:flex-row">
            {/* Basic Plan */}
            <div className="bg-white rounded-xl border border-gray-200 p-6 md:p-8 flex flex-col max-w-[100%] w-[100%] sm:max-w-[30%] md:w-[30%]">
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Free Sync</h3>
                <p className="text-gray-600 text-sm mb-4">Perfect for individuals or small teams</p>
                <div className="flex items-baseline">
                  <span className="text-3xl md:text-4xl font-bold">Free</span>
                </div>
              </div>
              <button
                onClick={() => handleCheckout("basic")}
                className="w-full py-2 px-4 bg-white border border-gray-200 rounded-lg text-sm font-medium mb-8
                             hover:border-gray-300 transition-colors"
              >
                Try For Free
              </button>
              <ul className="space-y-4 flex-grow">
                {[
                  'Limit backfill to 60 days only',
                  'Allow only one GA4 property',
                  'Basic email support',
                  '7-day trial of limited features',
                ].map((feature, index) => (
                  <li key={index} className="flex items-start text-sm">
                    <Check className="text-blue-500 w-4 h-4 mr-3 mt-0.5 flex-shrink-0" />
                    <span className="text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            {/* Professional Plan - Featured */}
            <div className="bg-white rounded-xl border-2 border-blue-500 p-6 md:p-8 flex flex-col relative shadow-lg transform md:scale-105 max-w-[100%] w-[100%] sm:max-w-[30%] md:w-[30%]"
            >
              {/* Popular badge */}
              <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 w-[120px]">
                <div className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-medium text-center">
                  Most Popular
                </div>
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">
                  Professional Sync
                </h3>
                <p className="text-gray-600 text-sm mb-4">
                  For growing teams who need more control
                </p>
                <div className="flex items-baseline flex-col md:flex-row">
                  <span className="text-3xl md:text-4xl font-bold">$449</span>
                  <span className="text-gray-600 ml-2">one-time</span>
                </div>
              </div>
              <button
                onClick={() => handleCheckout("professional")}
                className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg 
                             text-sm font-medium mb-8 transition-colors"
              >
                Get Started
              </button>
              <ul className="space-y-4 flex-grow">
              {[
  "Up to 3 GA4 property imports",
  "Unlimited historical data syncs",
  "Priority support (24h response) via email and Slack",
  
].map((feature, index) => (
  <li key={index} className="flex items-start text-sm">
    <Check className="text-blue-500 w-4 h-4 mr-3 mt-0.5 flex-shrink-0" />
    <span className="text-gray-600">{feature}</span>
  </li>
))}
              </ul>
            </div>

            {/* Enterprise Plan */}
            <div className="bg-white rounded-xl border border-gray-200 p-6 md:p-8 flex flex-col max-w-[100%] w-[100%] sm:max-w-[30%] md:w-[30%]">
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Enterprise</h3>
                <p className="text-gray-600 text-sm mb-4">
                  Custom solutions for large organizations
                </p>
                <div className="flex items-baseline flex-col md:flex-row">
                  <span className="text-3xl md:text-4xl font-bold">Custom</span>
                  <span className="text-gray-600 ml-2">pricing</span>
                </div>
              </div>
              <a href="mailto:support@databackfill.com">
              <button
                className="w-full py-2 px-4 bg-white border border-gray-200 rounded-lg text-sm font-medium mb-8
                             hover:border-gray-300 transition-colors"
              >
                Contact Sales
              </button>
              </a>
              <ul className="space-y-4 flex-grow">
                {[
                  "Up to 250 GA4 properties",
                  "Unlimited historical data syncs",
                  "Dedicated support + live chat",
                  "Integration support",
                ].map((feature, index) => (
                  <li key={index} className="flex items-start text-sm">
                    <Check className="text-blue-500 w-4 h-4 mr-3 mt-0.5 flex-shrink-0" />
                    <span className="text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Contact Section */}
          <div className="mt-16 md:mt-20 text-center">
            <p className="text-gray-600 mb-4 px-4">
              Not sure which plan is right for you? Contact our team for a
              personalized recommendation.
            </p>
            <a
              href="mailto:support@databackfill.com"
              className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
            >
              Get in touch
              <ArrowRight className="ml-2 w-4 h-4" />
            </a>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-12 md:py-20 bg-gray-50">
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto">
            <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">
              Frequently Asked Questions
            </h2>
            <div className="space-y-6">
            {[
  {
    question: "Is this really a one-time payment?",
    answer: "Yes, you pay once and get lifetime access to import your historical GA4 data into BigQuery for the number of properties included in your plan.",
  },
  {
    question: "What happens after I purchase?",
    answer: "You'll get immediate access to import your historical GA4 data into your BigQuery instance, with no recurring fees or hidden charges.",
  },
  // Keep your existing security/data-related FAQs
  {
    question: "Can I add more properties later?",
    answer: "Yes, you can upgrade to include more properties at any time. Contact our support team for assistance with upgrades.",
  }
].map((faq, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg border border-gray-200 p-6"
                >
                  <h3 className="text-lg font-semibold mb-2">{faq.question}</h3>
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default PricingPage;