import React, { useState } from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Search } from 'lucide-react';
import { Link } from 'react-router-dom';

const SupportPage = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const faqCategories = [
    {
      category: 'Getting Started',
      faqs: [
        {
          question: 'How do I create an account?',
          answer:
            'To create an account, click the "Start Backfilling" button on the homepage, provide your details, and follow the prompts.',
        },
        {
          question: 'What are the system requirements for DataBackfill?',
          answer:
            'DataBackfill is accessible via modern browsers like Chrome, Firefox, and Safari.',
        },
      ],
    },
    {
      category: 'Security & Privacy',
      faqs: [
        {
          question: 'Where is my GA4 data stored?',
          answer: 
            'Your data flows directly from GA4 to your BigQuery instance. We never store your analytics data on our servers - everything stays within your Google Cloud infrastructure.',
        },
        {
          question: 'Can other customers access my data?',
          answer:
            'No. Your data remains completely isolated in your BigQuery instance. Each customer\'s data is separate, and we never store analytics data on our infrastructure.',
        },
        {
          question: 'How do I control access to my data?',
          answer: 
            'You maintain full control through Google Cloud IAM (Identity and Access Management). You can grant or revoke access at any time through your Google Cloud Console, and monitor all data access through BigQuery audit logs.',
        },
        {
          question: 'What security measures are in place?',
          answer:
            'We use OAuth 2.0 for authentication, your data flows directly to your BigQuery instance, and you maintain complete control through Google Cloud IAM. We never store your analytics data on our servers.',
        },
      ],
    },
    {
      category: 'Account & Billing',
      faqs: [
        {
          question: 'How can I update my payment information?',
          answer:
            "Access the Stripe billing portal to manage your subscription and update payment information. Visit it here: " +
    "https://billing.stripe.com/p/login/aEUeXhaokdJm4a4eUU",
        },
        {
          question: 'How do I change my subscription plan?',
          answer:
            "You can manage and change your subscription plan through the Stripe billing portal at https://billing.stripe.com/p/login/aEUeXhaokdJm4a4eUU"
,
        },
      ],
    },
    {
      category: 'Troubleshooting',
      faqs: [
        {
          question: 'What should I do if my data is not syncing?',
          answer: (
            <span>
              First, check your GA4 Property ID and ensure all APIs are enabled. Refer to our{' '}
              <Link 
                to="/documentation" 
                className="text-blue-600 hover:text-blue-700 underline"
              >
                detailed guide here
              </Link>
              .
            </span>
          )
        },
        {
          question: 'I received an error during authorization. What should I do?',
          answer:
            'Ensure you have enabled the required APIs for your Google Cloud project.',
        },
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      
      {/* Hero Section */}
      <section className="pt-20 bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">Support Center</h1>
          <p className="text-base md:text-lg text-gray-600 mb-8">
            Find answers, get help, and make the most of your DataBackfill experience.
          </p>
          {/* Future Search Box */}
          
        </div>
      </section>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        {/* FAQ Categories */}
        <section>
          <h2 className="text-xl md:text-2xl font-semibold mb-6">Knowledge Base / FAQs</h2>
          <div className="space-y-8 md:space-y-12">
            {faqCategories.map((category, index) => (
              <div key={index} className="space-y-6">
                <h3 className="text-lg md:text-xl font-medium text-gray-900">
                  {category.category}
                </h3>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {category.faqs.map((faq, idx) => (
                    <div
                      key={idx}
                      className="bg-white p-4 md:p-6 rounded-lg shadow-sm hover:shadow-md 
                               transition-shadow border border-gray-200"
                    >
                      <h4 className="text-base md:text-lg font-semibold mb-2">
                        {faq.question}
                      </h4>
                      <p className="text-gray-600 text-sm md:text-base">
                        {faq.answer}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Contact Support Section */}
        <section className="mt-12 md:mt-16">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-xl md:text-2xl font-bold mb-4">Need More Help?</h2>
            <p className="text-gray-600 mb-8">
              Our support team is here to help you succeed with DataBackfill.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
            <a 
 href="mailto:support@databackfill.com"
 className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
            transition shadow-sm inline-block"
>
 Contact Support
</a>
              <a 
                href="https://backfillingcrew.slack.com" 
                className="px-6 py-3 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200 
                          transition flex items-center justify-center"
              >
                Join our Slack Community
              </a>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default SupportPage;