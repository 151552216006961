import React, { useState } from 'react';
import { Search, ChevronRight, Shield, Lock, Mail, HelpCircle, ExternalLink, Menu, X, ChevronDown, Copy, CheckCircle2 } from 'lucide-react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

const DocumentationPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);
  const [copiedText, setCopiedText] = useState('');

  const sections = [
    {
      title: 'Getting Started',
      items: [
        { name: 'Introduction', id: 'introduction' },
        { name: 'How It Works', id: 'how-it-works' },
        { name: 'Prerequisites', id: 'prerequisites' },
      ]
    },
    {
      title: 'Configuration',
      items: [
        { name: 'GA4 Setup', id: 'ga4-setup' },
        { name: 'Google Cloud APIs', id: 'cloud-apis' },
        { name: 'IAM Roles', id: 'iam-roles' },
        { name: 'BigQuery Setup', id: 'bigquery-setup' },
        { name: 'Finding Project ID', id: 'finding-project-id' },
        { name: 'Finding Dataset ID', id: 'finding-dataset-id' },
        { name: 'Finding Table ID', id: 'finding-table-id' }
      ]
    },
    {
      title: 'Security & Privacy',
      items: [
        { name: 'Data Flow & Storage', id: 'data-flow' },
        { name: 'Access Controls', id: 'access-controls' },
        { name: 'Security Best Practices', id: 'security-practices' }
      ]
    },
    {
      title: 'Using DataBackfill',
      items: [
        { name: 'Syncing Data', id: 'syncing-data' },
        { name: 'Monitoring Progress', id: 'monitoring-progress' },
        { name: 'Troubleshooting', id: 'troubleshooting' }
      ]
    }
  ];

  const handleCopySchema = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText('schema');
    setTimeout(() => setCopiedText(''), 2000);
  };

  const toggleSection = (index) => {
    setExpandedSection(expandedSection === index ? null : index);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      setIsSidebarOpen(false);
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const schemaText = `[
  {
    "name": "eventName",
    "type": "STRING",
    "mode": "REQUIRED"
  },
{
    "name": "eventDate",
    "type": "STRING",
    "mode": "REQUIRED"
  },
  {
    "name": "eventCount",
    "type": "INTEGER",
    "mode": "REQUIRED"
  },
  {
    "name": "page_view",
    "type": "INTEGER",
    "mode": "NULLABLE"
  },
  {
    "name": "session_start",
    "type": "INTEGER",
    "mode": "NULLABLE"
  },
  {
    "name": "user_engagement",
    "type": "INTEGER",
    "mode": "NULLABLE"
  },
  {
    "name": "first_visit",
    "type": "INTEGER",
    "mode": "NULLABLE"
  },
  {
    "name": "scroll",
    "type": "INTEGER",
    "mode": "NULLABLE"
  },
  {
    "name": "form_submit",
    "type": "INTEGER",
    "mode": "NULLABLE"
  },
  {
    "name": "form_start",
    "type": "INTEGER",
    "mode": "NULLABLE"
  },
  {
    "name": "view_search_results",
    "type": "INTEGER",
    "mode": "NULLABLE"
  },
  {
    "name": "click",
    "type": "INTEGER",
    "mode": "NULLABLE"
  },
  {
    "name": "Channel",
    "type": "STRING",
    "mode": "NULLABLE"
  },
  {
    "name": "Event_Type",
    "type": "STRING",
    "mode": "NULLABLE"
  },
  {
    "name": "Is_Conversion",
    "type": "BOOLEAN",
    "mode": "NULLABLE"
  }
]`;

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      
      <div className="pt-16 md:pt-20">
        <div className="bg-white border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-6 md:py-8">
              <h1 className="text-2xl md:text-3xl font-bold text-gray-900">Documentation</h1>
              <p className="mt-2 text-base md:text-lg text-gray-600">Everything you need to know about using DataBackfill</p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <div className="lg:flex lg:gap-8">
          {/* Mobile Sidebar Toggle */}
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="lg:hidden w-full mb-4 flex items-center justify-between px-4 py-2 bg-white rounded-lg border border-gray-200"
          >
            <span className="font-medium text-gray-900">Documentation Menu</span>
            {isSidebarOpen ? <X size={20} /> : <Menu size={20} />}
          </button>

          {/* Side Navigation */}
          <div className={`
            lg:w-64 lg:block lg:shrink-0
            fixed lg:static inset-0 z-30 lg:z-0
            transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0
            transition-transform duration-300 ease-in-out
            bg-white lg:bg-transparent
            ${isSidebarOpen ? 'block' : 'hidden'}
          `}>
            {/* Mobile Overlay */}
            <div 
              className={`fixed inset-0 bg-black/20 lg:hidden ${isSidebarOpen ? 'block' : 'hidden'}`}
              onClick={() => setIsSidebarOpen(false)}
            />

            {/* Sidebar Content */}
            <div className="relative h-full lg:h-auto bg-white p-4 overflow-y-auto lg:sticky lg:top-28 rounded-lg border border-gray-200">
              <div className="space-y-6">
                {sections.map((section, index) => (
                  <div key={index}>
                    <button
                      onClick={() => toggleSection(index)}
                      className="flex items-center justify-between w-full text-left font-semibold text-gray-900 hover:text-blue-600"
                    >
                      <span>{section.title}</span>
                      <ChevronDown 
                        size={16} 
                        className={`transform transition-transform ${expandedSection === index ? 'rotate-180' : ''}`}
                      />
                    </button>
                    <div className={`mt-2 space-y-1 ${expandedSection === index ? 'block' : 'hidden'} lg:block`}>
                      {section.items.map((item, itemIndex) => (
                        <button
                          key={itemIndex}
                          onClick={() => scrollToSection(item.id)}
                          className="block w-full text-left pl-4 py-2 text-sm text-gray-600 hover:text-blue-600 hover:bg-gray-50 rounded-lg"
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 max-w-3xl">
            {/* Introduction */}
            <section id="introduction" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Introduction to DataBackfill</h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                DataBackfill is a powerful tool designed to simplify the process of synchronizing your Google Analytics 4 (GA4) 
                data with BigQuery. Whether you're looking to analyze historical data or maintain ongoing syncs, our platform 
                makes the process seamless and efficient.
              </p>
              <div className="bg-blue-50 rounded-lg p-6 mb-6">
                <img 
                  src="/dashboard-overview.png" 
                  alt="Dashboard Overview" 
                  className="rounded-lg mb-4 w-full"
                />
                <p className="text-sm text-gray-600">
                  The DataBackfill dashboard provides a clear overview of your sync status and history
                </p>
              </div>
            </section>

            {/* How It Works */}
            <section id="how-it-works" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">How It Works</h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                Your data flows directly from GA4 to your BigQuery instance - we never store your analytics data. 
                You maintain complete control through Google Cloud IAM, and can monitor or revoke access at any time.
              </p>
              <div className="space-y-4">
                <div className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">1. Connect Your Accounts</h3>
                  <p className="text-gray-600">Grant access to your GA4 property and configure your BigQuery destination</p>
                </div>
                <div className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">2. Select Your Data Range</h3>
                  <p className="text-gray-600">Choose the time period for which you want to sync data</p>
                </div>
                <div className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">3. Start Syncing</h3>
                  <p className="text-gray-600">Monitor the progress as your data is transferred to BigQuery</p>
                </div>
              </div>
            </section>

            {/* Prerequisites */}
            <section id="prerequisites" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Prerequisites</h2>
              <p className="text-gray-600 mb-6">Before you begin, ensure you have:</p>
              <ul className="space-y-4">
                <li className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">GA4 Property Access</h3>
                  <p className="text-gray-600">Editor role access to your GA4 property</p>
                </li>
                <li className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">Google Cloud Project APIs</h3>
                  <p className="text-gray-600">A Google Cloud project with BigQuery API and Google Analytics Data API enabled</p>
                </li>
                <li className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">Required IAM Roles</h3>
                  <p className="text-gray-600">The following IAM roles in your Google Cloud project:</p>
                  <ul className="mt-2 ml-4 space-y-1 text-gray-600 text-sm">
                    <li>• BigQuery Data Editor (roles/bigquery.dataEditor)</li>
                    <li>• BigQuery Data Viewer (roles/bigquery.dataViewer)</li>
                    <li>• BigQuery Job User (roles/bigquery.jobUser)</li>
                    <li>• BigQuery User (roles/bigquery.user)</li>
                    <li>• Viewer (roles/viewer)</li>
                  </ul>
                </li>
                <li className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">BigQuery Dataset</h3>
                  <p className="text-gray-600">A BigQuery dataset where your GA4 data will be stored</p>
                </li>
              </ul>
            </section>

            {/* GA4 Setup */}
            <section id="ga4-setup" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">GA4 Setup</h2>
              <p className="text-gray-600 mb-6">
                To enable DataBackfill to access your GA4 data, you'll need to grant the appropriate permissions:
              </p>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Granting Editor Access</h3>
                  <ol className="space-y-4 text-gray-600">
                    <li>1. Open your GA4 property settings</li>
                    <li>2. Navigate to Admin → Property → Access Management</li>
                    <li>3. Click the "+" button to add a new user</li>
                    <li>4. Add your Google account email as an Editor (the same email you'll use to sign in to DataBackfill)</li>
                  </ol>
                </div>
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Finding Your Property ID</h3>
                  <img 
                    src="/ga4-property.png" 
                    alt="GA4 Property ID Location" 
                    className="rounded-lg mb-4 w-full"
                  />
                  <p className="text-gray-600">
                    Your GA4 Property ID can be found in the Admin section under Property Settings
                  </p>
                </div>
              </div>
            </section>

            {/* Google Cloud APIs Setup */}
            <section id="cloud-apis" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Google Cloud APIs Setup</h2>
              <p className="text-gray-600 mb-6">
                Enable the required APIs in your Google Cloud project:
              </p>
              <div className="bg-white p-6 rounded-lg border border-gray-200">
                <h3 className="font-semibold mb-4">Enabling Required APIs</h3>
                <ol className="space-y-4 text-gray-600">
                  <li>1. Open your Google Cloud Console (console.cloud.google.com)</li>
                  <li>2. Navigate to API & Services</li>
                  <li>3. Click on "Enable APIs & Services"</li>
                  <li>4. Search for and enable:
                    <ul className="ml-6 mt-2 space-y-2">
                      <li>• BigQuery API</li>
                      <li>• Google Analytics Data API</li>
                    </ul>
                  </li>
                </ol>
                <img 
                  src="/api-setup.png" 
                  alt="Google Cloud APIs Setup" 
                  className="rounded-lg mt-4 w-full"
                />
                <img 
                  src="/googleanalytics.png" 
                  alt="Google Cloud APIs Setup" 
                  className="rounded-lg mt-4 w-full"
                />
              </div>
            </section>

            {/* IAM Roles Section */}
            <section id="iam-roles" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">IAM Roles Setup</h2>
              <p className="text-gray-600 mb-6">
                Grant the necessary IAM roles in your Google Cloud project:
              </p>
              <div className="bg-white p-6 rounded-lg border border-gray-200">
                <h3 className="font-semibold mb-4">Setting Up IAM Roles</h3>
                <ol className="space-y-4 text-gray-600">
                  <li>1. In Google Cloud Console, navigate to IAM & Admin</li>
                  <li>2. Select "IAM"</li>
                  <li>3. Click "Grant Access"</li>
                  <li>4. Add your email and assign these roles:
                    <ul className="ml-6 mt-2 space-y-2">
                      <li>• BigQuery Data Editor (roles/bigquery.dataEditor)</li>
                      <li>• BigQuery Data Viewer (roles/bigquery.dataViewer)</li>
                      <li>• BigQuery Job User (roles/bigquery.jobUser)</li>
                      <li>• BigQuery User (roles/bigquery.user)</li>
                      <li>• Viewer (roles/viewer)</li>
                    </ul>
                  </li>
                </ol>
                <img 
                  src="/iam-setup.png" 
                  alt="IAM Roles Setup" 
                  className="rounded-lg mt-4 w-full"
                />
              </div>
            </section>

            {/* BigQuery Setup */}
            <section id="bigquery-setup" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">BigQuery Setup</h2>
              <p className="text-gray-600 mb-6">
                Before syncing data, you'll need to set up your BigQuery environment with the correct schema:
              </p>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="font-semibold">Table Schema</h3>
                    <button
                      onClick={() => handleCopySchema(schemaText)}
                      className="flex items-center text-blue-600 hover:text-blue-700"
                    >
                      {copiedText === 'schema' ? (
                        <CheckCircle2 size={20} className="mr-2" />
                      ) : (
                        <Copy size={20} className="mr-2" />
                      )}
                      {copiedText === 'schema' ? 'Copied!' : 'Copy Schema'}
                    </button>
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg overflow-auto max-h-96">
                    <pre className="text-sm text-gray-800">{schemaText}</pre>
                  </div>
                </div>
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Creating Your Table</h3>
                  <ol className="mt-2 space-y-2 text-sm text-gray-600">
                    <li>1. Google Cloud Console → BigQuery</li>
                    <li>2. Select your dataset</li>
                    <li>3. Click "Create Table"</li>
                    <li>4. Under "Schema", click "Edit as text"</li>
                    <li>5. Paste the schema and save</li>
                  </ol>
                </div>
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Understanding the Schema</h3>
                  <p className="text-gray-600 mb-4">
                    The schema captures essential GA4 event data:
                  </p>
                  <ul className="space-y-2 text-sm text-gray-600">
                    <li>• <strong>eventName & eventCount</strong>: Core event metrics (required fields)</li>
                    <li>• <strong>Event-specific counts</strong>: Individual tracking for page views, sessions, etc.</li>
                    <li>• <strong>Metadata fields</strong>: Channel, Event Type, and conversion tracking</li>
                  </ul>
                </div>
              </div>
            </section>
            
            {/* Finding Your Project ID */}
<section id="finding-project-id" className="mb-12 scroll-mt-28">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">Finding Your Google Cloud Project ID</h2>
  <p className="text-gray-600 mb-6 leading-relaxed">
    Your Project ID is a unique identifier for your Google Cloud project. 
    It’s used when configuring your BigQuery dataset and allowing API access.
  </p>
  <div className="bg-white p-6 rounded-lg border border-gray-200">
    <h3 className="font-semibold mb-4">Steps to Locate Your Project ID</h3>
    <ol className="space-y-4 text-gray-600">
      <li>1. Sign in to the <a href="https://console.cloud.google.com/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700">Google Cloud Console</a>.</li>
      <li>2. In the top navigation bar, select the project dropdown.</li>
      <li>3. Find your project in the list. The <strong>Project ID</strong> is shown beneath the project’s name.</li>
      <li>4. Copy this value to use in DataBackfill’s <strong>Project ID</strong> field.</li>
    </ol>
    <img 
      src="/placeholder-project-id.png" 
      alt="Project ID Location Screenshot"
      className="rounded-lg mt-4 w-full"
    />
  </div>
</section>

{/* Finding Your BigQuery Dataset ID */}
<section id="finding-dataset-id" className="mb-12 scroll-mt-28">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">Finding Your BigQuery Dataset ID</h2>
  <p className="text-gray-600 mb-6 leading-relaxed">
    The Dataset ID identifies the specific BigQuery dataset where your GA4 data 
    will be stored. You can find it in the BigQuery Console.
  </p>
  <div className="bg-white p-6 rounded-lg border border-gray-200">
    <h3 className="font-semibold mb-4">Steps to Locate Your Dataset ID</h3>
    <ol className="space-y-4 text-gray-600">
      <li>1. In the Cloud Console, open the <strong>BigQuery</strong> section.</li>
      <li>2. In the left-hand navigation, expand your project to see your datasets.</li>
      <li>3. Right click on the <strong>Dataset</strong> and choose copy id. 
        It follows the format <em>projectName.datasetName</em>, but you only need the dataset portion (e.g., <em>myDataset</em>).</li>
      <li>4. Copy the dataset name to use in DataBackfill’s <strong>Dataset ID</strong> field.</li>
    </ol>
    <img 
      src="/placeholder-dataset-id.png" 
      alt="Dataset ID Location Screenshot"
      className="rounded-lg mt-4 w-full"
    />
  </div>
</section>

{/* Finding Your BigQuery Table ID */}
<section id="finding-table-id" className="mb-12 scroll-mt-28">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">Finding Your BigQuery Table ID</h2>
  <p className="text-gray-600 mb-6 leading-relaxed">
    The Table ID is the specific table in your BigQuery dataset that will store 
    the synchronized GA4 data.
  </p>
  <div className="bg-white p-6 rounded-lg border border-gray-200">
    <h3 className="font-semibold mb-4">Steps to Locate Your Table ID</h3>
    <ol className="space-y-4 text-gray-600">
      <li>1. In the BigQuery console, click on your dataset to expand its contents.</li>
      <li>2. Locate the table you want to use—this is your <strong>Table ID</strong>.</li>
      <li>3. Make sure you created the BigQuery table from the step aboved called BigQuery Setup. Then note its name (e.g., <em>events_table</em>).</li>
      <li>4. Use this table name in DataBackfill’s <strong>Table ID</strong> field.</li>
    </ol>
    <img 
      src="/placeholder-table-id.png" 
      alt="Table ID Location Screenshot"
      className="rounded-lg mt-4 w-full"
    />
  </div>
</section>


            {/* Security & Privacy Section */}
            <section id="data-flow" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Data Flow & Storage</h2>
              <p className="text-gray-600 mb-6">
                DataBackfill is designed with a security-first approach to protect your data. Understanding how your data flows is crucial:
              </p>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">How Your Data Flows</h3>
                  <ol className="space-y-4 text-gray-600">
                    <li>1. Your data is read directly from your GA4 property</li>
                    <li>2. The data is transmitted securely to your BigQuery instance</li>
                    <li>3. We never store your analytics data on our servers</li>
                    <li>4. All data remains within your Google Cloud infrastructure</li>
                  </ol>
                </div>
                
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Data Storage Policy</h3>
                  <ul className="space-y-4 text-gray-600">
                    <li className="flex items-start">
                      <Shield className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>Your data resides exclusively in your BigQuery instance</span>
                    </li>
                    <li className="flex items-start">
                      <Shield className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>Complete isolation from other customers' data</span>
                    </li>
                    <li className="flex items-start">
                      <Shield className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>No persistent storage of your analytics data on our infrastructure</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Access Controls Section */}
            <section id="access-controls" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Access Controls</h2>
              <p className="text-gray-600 mb-6">
                You maintain complete control over who can access your data through Google Cloud's IAM:
              </p>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Managing Access</h3>
                  <ul className="space-y-4 text-gray-600">
                    <li className="flex items-start">
                      <Lock className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>Control access through Google Cloud IAM permissions</span>
                    </li>
                    <li className="flex items-start">
                      <Lock className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>Revoke access at any time via Google Cloud Console</span>
                    </li>
                    <li className="flex items-start">
                      <Lock className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>Monitor all data access through BigQuery audit logs</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Security Best Practices Section */}
            <section id="security-practices" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Security Best Practices</h2>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Recommended Security Settings</h3>
                  <ul className="space-y-4 text-gray-600">
                    <li>• Regularly review access permissions in Google Cloud Console</li>
                    <li>• Enable audit logging for your BigQuery instance</li>
                    <li>• Monitor data access patterns through BigQuery logs</li>
                  </ul>
                </div>
                
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Regular Security Audits</h3>
                  <p className="text-gray-600 mb-4">
                    We recommend performing regular security audits:
                  </p>
                  <ul className="space-y-2 text-gray-600">
                    <li>• Review IAM permissions monthly</li>
                    <li>• Check BigQuery audit logs for unusual activity</li>
                    <li>• Verify service account access permissions</li>
                    <li>• Update access credentials regularly</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Using DataBackfill Section */}
            <section id="syncing-data" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Using DataBackfill</h2>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Starting a Sync</h3>
                  <ol className="space-y-2 text-gray-600">
                    <li>1. Enter your GA4 Property ID</li>
                    <li>2. Select your date range</li>
                    <li>3. Click "Start Sync" to begin the process</li>
                  </ol>
                </div>
              </div>
            </section>

            {/* Monitoring Progress Section */}
            <section id="monitoring-progress" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Monitoring Progress</h2>
              <div className="bg-white p-6 rounded-lg border border-gray-200">
                <img 
                  src="/dashboard-overview.png" 
                  alt="Sync Status View" 
                  className="rounded-lg mb-4 w-full"
                />
                <p className="text-gray-600 mb-4">
                  Track your sync progress from the dashboard, which shows:
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li>• Total syncs completed</li>
                  <li>• Latest sync status and timestamp</li>
                  <li>• Recent activity history</li>
                </ul>
              </div>
            </section>

            {/* Troubleshooting Section */}
            <section id="troubleshooting" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Troubleshooting</h2>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Common Issues</h3>
                  <div className="space-y-4">
                    <div>
                      <p className="font-medium text-gray-900">Access Denied Errors</p>
                      <p className="text-gray-600">Verify that the Editor role has been properly assigned in GA4</p>
                    </div>
                    <div>
                      <p className="font-medium text-gray-900">Sync Failures</p>
                      <p className="text-gray-600">Check your BigQuery permissions and schema configuration</p>
                    </div>
                    <div>
                      <p className="font-medium text-gray-900">Data Not Appearing</p>
                      <p className="text-gray-600">Ensure your selected date range contains data in GA4</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Support Section */}
            <section id="support" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Need Help?</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
                <div className="bg-white rounded-lg border border-gray-200 p-6">
                  <div className="flex items-center space-x-3 mb-4">
                    <Mail className="text-blue-600" size={24} />
                    <h3 className="font-semibold text-gray-900">Email Support</h3>
                    </div>
                  <p className="text-gray-600 mb-4">Get in touch with our support team</p>
                  <a href="mailto:support@DataBackfill.com" className="text-blue-600 hover:text-blue-700 font-medium">
                    support@DataBackfill.com
                  </a>
                </div>
                <div className="bg-white rounded-lg border border-gray-200 p-6">
                  <div className="flex items-center space-x-3 mb-4">
                    <HelpCircle className="text-blue-600" size={24} />
                    <h3 className="font-semibold text-gray-900">Help Center</h3>
                  </div>
                  <p className="text-gray-600 mb-4">Browse our knowledge base</p>
                  <Link 
                    to="/support" 
                    className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
                  >
                    Visit Help Center
                    <ExternalLink size={16} className="ml-1" />
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DocumentationPage;