import React from 'react';
import { Link } from 'react-router-dom';
import { Shield, Code, Database, Users, Lightbulb, ChevronRight, Github } from 'lucide-react';
import Header from './shared/Header';
import Footer from './shared/Footer';

// LinkedIn Logo SVG Component
const LinkedInLogo = () => (
  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
  </svg>
);

const AboutPage = () => {
  const teamMembers = [
    {
      name: "S.D. Hines",
      role: "Founder & Data Analytics Expert",
      image: "/SD.jpg",
      linkedin: "https://linkedin.com/in/sarittahines",
      description: [
        "Proven track record in data-driven sales engineering with Google",
        "Expert in translating complex data solutions into business value",
        "Specialist in data warehousing and ETL pipeline optimization",
        "Strong background in GTM strategy and product launches"
      ],
      skills: ["Data Analytics",
        "Google Cloud Solutions",
        "Data Engineering",
        "ETL Architecture",
        "Data Warehousing",
        "Solution Design",
        "Python",
      "React/TypeScript"],
        intro: "Leveraging my passion for data and proven success in sales engineering, I help businesses unlock the power of Google Cloud Platform to achieve their data-driven goals. I specialize in translating complex data solutions into tangible business value, guiding clients through data ingestion, storage, processing, and analytics.",
        icon: <Database className="w-6 h-6" />
    },
    {
      name: "Connor James Satori Hoshi-Aiko",
      role: "Lead Developer",
      image: "/connor.jpeg",
      github: "https://github.com/xwaistmenx",
      description: [
        "Full-stack architect specializing in scalable cloud solutions",
        "Expert in frontend and backend development across multiple frameworks",
        "Specialist in DevOps and system architecture",
        "Experience integrating AI/ML systems into production environments"
      ],
      skills: ["React/TypeScript", 
        "Node.js", 
        "Python",
        "Cloud Architecture",
        "DevOps/CI/CD",
        "System Design",
        "Microservices",
        "AI Integration"],
        intro: "A seasoned software engineer with 12 years of experience in developing large-scale platforms. Having led the creation of over 30 web platforms from e-commerce to SaaS solutions, I bring expertise in building efficient, scalable systems that are ready for tomorrow's challenges.",
        icon: <Code className="w-6 h-6" />
    }
  ];

  const renderSocialLink = (member) => {
    if (member.linkedin) {
      return (
        <a
          href={member.linkedin}
          target="_blank"
          rel="noopener noreferrer"
          className="p-2 text-gray-400 hover:text-blue-600 transition-colors"
        >
          <LinkedInLogo />
        </a>
      );
    }
    if (member.github) {
      return (
        <a
          href={member.github}
          target="_blank"
          rel="noopener noreferrer"
          className="p-2 text-gray-400 hover:text-gray-600 transition-colors"
        >
          <Github className="w-6 h-6" />
        </a>
      );
    }
    return null;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Header />
      <div className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Mission Statement */}
          <div className="max-w-3xl mx-auto text-center mb-20">
            <h2 className="text-3xl font-bold text-gray-900 mb-8">Our Mission</h2>
            <div className="bg-gradient-to-r from-blue-50 to-purple-50 rounded-2xl p-8 border border-blue-100">
              <p className="text-lg text-gray-700 leading-relaxed mb-6">
                We founded DataBackfill with a clear purpose: to simplify how organizations handle their GA4 historical data. 
                Having worked extensively with data migration and transformation projects, we saw the need for a solution 
                that bridged the gap between complexity and usability.
              </p>
              <p className="text-lg text-gray-700 leading-relaxed">
                At DataBackfill, our mission is to make wrangling your GA4 data a breeze and transform it into actionable 
                insights—without the headaches. We believe every business, big or small, should have effortless access to 
                their historical GA4 data in BigQuery, enabling them to unlock competitive advantages, cut through complexity, 
                and get straight to the good stuff: meaningful analytics that drive results.
              </p>
            </div>
          </div>

          {/* Team Section Title */}
          <div className="text-center mb-20">
            <h1 className="text-4xl font-bold text-gray-900 mb-6">
              The Minds Behind DataBackfill
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Combining deep expertise in data analytics with a passion for making complex operations simple.
            </p>
          </div>

          {/* Team Members */}
          <div className="grid md:grid-cols-2 gap-12 mb-20">
            {teamMembers.map((member, index) => (
              <div key={index} className="relative">
                <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8 
                              hover:shadow-lg transition-shadow relative">
                  <div className="absolute -top-3 left-8 w-12 h-12 bg-gradient-to-br from-blue-500 to-blue-600 
                                rounded-xl flex items-center justify-center text-white shadow-lg">
                    {member.icon}
                  </div>

                  <div className="mt-6">
                    <div className="flex items-center justify-between mb-6">
                      <div className="flex items-center space-x-6">
                        <div className="w-24 h-24 rounded-full overflow-hidden border-4 border-white shadow-lg">
                          <img
                            src={member.image}
                            alt={member.name}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div>
                          <h2 className="text-2xl font-bold text-gray-900">{member.name}</h2>
                          <p className="text-blue-600 font-medium">{member.role}</p>
                        </div>
                      </div>
                      {renderSocialLink(member)}
                    </div>

                    <p className="text-gray-600 mb-8 leading-relaxed">{member.intro}</p>

                    <div className="space-y-4 mb-8">
                      {member.description.map((point, idx) => (
                        <div key={idx} className="flex items-center">
                          <div className="w-1.5 h-1.5 rounded-full bg-blue-600 mr-3 flex-shrink-0" />
                          <span className="text-gray-600">{point}</span>
                        </div>
                      ))}
                    </div>

                    <div className="flex flex-wrap gap-2">
                      {member.skills.map((skill, idx) => (
                        <span 
                          key={idx}
                          className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm font-medium"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Core Values */}
          <div className="mb-20">
          <h2 className="text-3xl font-bold mb-12 text-center">Core Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="rounded-2xl border border-gray-200 p-6">
                <div className="w-12 h-12 bg-blue-50 rounded-xl flex items-center justify-center mb-6">
                  <Users className="text-blue-600 w-6 h-6" />
                </div>
                <h3 className="text-xl font-semibold mb-4">User-Centric Design</h3>
                <p className="text-gray-600">
                  Prioritize ease of use and focus on building tools that are both powerful 
                  and user-friendly.
                </p>
              </div>

              <div className="rounded-2xl border border-gray-200 p-6">
                <div className="w-12 h-12 bg-purple-50 rounded-xl flex items-center justify-center mb-6">
                  <Shield className="text-purple-600 w-6 h-6" />
                </div>
                <h3 className="text-xl font-semibold mb-4">Security First</h3>
                <p className="text-gray-600">
                  Your data is valuable. We adhere to the highest standards of data 
                  protection and privacy.
                </p>
              </div>

              <div className="rounded-2xl border border-gray-200 p-6">
                <div className="w-12 h-12 bg-green-50 rounded-xl flex items-center justify-center mb-6">
                  <Lightbulb className="text-green-600 w-6 h-6" />
                </div>
                <h3 className="text-xl font-semibold mb-4">Innovation</h3>
                <p className="text-gray-600">
                  We are committed to continual improvement and staying ahead of emerging trends 
                  in data analytics and integration.
                </p>
              </div>
            </div>
          </div>

          {/* CTA Section */}
          <section className="py-12 md:py-20">
            <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
              <div className="bg-blue-600 rounded-3xl p-6 md:p-12 relative overflow-hidden">
                <div className="absolute top-0 right-0 w-64 h-64 bg-blue-500 rounded-full transform translate-x-1/2 -translate-y-1/2 blur-2xl" />
                <div className="absolute bottom-0 left-0 w-64 h-64 bg-blue-700 rounded-full transform -translate-x-1/2 translate-y-1/2 blur-2xl" />
                
                <div className="relative max-w-lg mx-auto text-center">
                  <h2 className="text-2xl md:text-3xl font-bold text-white mb-6">
                    Why Choose Us?
                  </h2>
                  <p className="text-lg text-white mb-8">
                    We're not just another software provider. Our solution is built by industry experts who 
                    understand the challenges of modern data teams. We continuously listen to feedback, 
                    adapt, and innovate to provide unmatched value to our users.
                  </p>
                  <Link
                    to="/pricing"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    className="inline-flex items-center px-6 md:px-8 py-3 md:py-4 bg-white text-blue-600
                             rounded-xl text-base md:text-lg font-semibold hover:bg-blue-50 transition-all
                             shadow-lg hover:shadow-xl hover:translate-y-[-1px]"
                  >
                    Start Backfilling Now
                    <ChevronRight className="ml-2" size={20} />
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;