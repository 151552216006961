import React from 'react';
import { 
  Database, 
  Shield, 
  Zap, 
  Settings, 
  Building, 
  Headphones, 
  Layout,
  CreditCard,
  ChevronRight,
  Lock
} from 'lucide-react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

const FeaturesPage = () => {
  const features = [
    {
      icon: <Shield className="w-6 h-6" />,
      title: "Security-First Architecture",
      subtitle: "Your Data, Your Control",
      description: "Your data flows directly to your BigQuery instance - we never store your GA4 data. Complete isolation ensures no other customers can access your data.",
      color: "blue"
    },
    {
      icon: <Database className="w-6 h-6" />,
      title: "Complete GA4 Data Synchronization",
      subtitle: "Direct to Your BigQuery",
      description: "Seamlessly backfill and synchronize your historical GA4 data directly into your BigQuery instance for unmatched data analysis and insights.",
      color: "blue"
    },
    {
      icon: <Zap className="w-6 h-6" />,
      title: "Real-time Data Processing",
      subtitle: "Accelerate Your Analytics",
      description: "Process and analyze millions of data points in real-time, empowering your business with accurate, up-to-date information whenever you need it.",
      color: "purple"
    },
    {
      icon: <Settings className="w-6 h-6" />,
      title: "Customizable Configurations",
      subtitle: "Tailor to Your Needs",
      description: "Our interface lets you configure your data sync settings according to your unique business requirements, including custom BigQuery configurations.",
      color: "purple"
    },
    {
      icon: <Building className="w-6 h-6" />,
      title: "Multi-property Support",
      subtitle: "Efficient Management",
      description: "Sync data from multiple GA4 properties, providing holistic analytics and enabling more comprehensive insights across your organization's datasets.",
      color: "blue"
    },
    {
      icon: <Headphones className="w-6 h-6" />,
      title: "Priority Support",
      subtitle: "Expert Assistance",
      description: "Get priority support with response times tailored to your plan, including dedicated assistance for enterprise clients.",
      color: "blue"
    },
    {
      icon: <Layout className="w-6 h-6" />,
      title: "Easy-to-Use Interface",
      subtitle: "Intuitive Experience",
      description: "Navigate a clean and intuitive dashboard that simplifies complex data operations. No more headaches, just seamless workflows.",
      color: "purple"
    },
    {
      icon: <CreditCard className="w-6 h-6" />,
      title: "Flexible Pricing Plans",
      subtitle: "Scalable Solutions",
      description: "Choose a plan that fits your business needs, whether you're a small team or an enterprise organization. Our pricing is simple, transparent, and designed to scale.",
      color: "purple"
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <Header />

      {/* Hero Section */}
      <section className="pt-20 md:pt-32 pb-12 md:pb-24 relative">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-white to-white"></div>
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center max-w-3xl mx-auto">
            <div className="inline-block px-4 py-1 bg-blue-50 rounded-full text-blue-600 font-medium text-sm mb-4">
              Product Suite
            </div>
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4 md:mb-6">
              Everything you need for seamless GA4 data sync
            </h1>
            <p className="text-lg md:text-xl text-gray-600 px-4 sm:px-0">
              Powerful features designed for secure and effortless data synchronization directly to your BigQuery
            </p>
          </div>
        </div>
      </section>

      {/* Features Grid */}
      <section className="py-12 md:py-20">
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {features.map((feature, index) => (
              <div 
                key={index} 
                className={`bg-${feature.color}-50 rounded-2xl p-6 md:p-8 hover:shadow-lg transition-all`}
              >
                <div className={`w-12 h-12 bg-${feature.color}-100 rounded-xl flex items-center justify-center mb-6`}>
                  <div className={`text-${feature.color}-600`}>
                    {feature.icon}
                  </div>
                </div>
                <div className="mb-2 text-sm font-medium text-gray-600">
                  {feature.subtitle}
                </div>
                <h3 className="text-xl font-semibold mb-4">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Security Features Section */}
      <section className="py-12 md:py-20 bg-gray-50">
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <div className="inline-block px-4 py-1 bg-blue-50 rounded-full text-blue-600 font-medium text-sm mb-4">
              Security & Privacy
            </div>
            <h2 className="text-2xl md:text-3xl font-bold mb-6">Your Data Stays In Your Control</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              We designed DataBackfill with security as our top priority. Your data flows directly to your BigQuery instance.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Data Flow Card */}
            <div className="bg-white rounded-xl p-6 md:p-8 shadow-sm">
              <h3 className="text-xl font-semibold mb-4">How Your Data Flows</h3>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <Shield className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                  <span className="text-gray-600">Data flows directly from GA4 to your BigQuery instance</span>
                </li>
                <li className="flex items-start">
                  <Shield className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                  <span className="text-gray-600">We never store or access your analytics data</span>
                </li>
                <li className="flex items-start">
                  <Shield className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                  <span className="text-gray-600">Complete data isolation from other customers</span>
                </li>
              </ul>
            </div>

            {/* Access Control Card */}
            <div className="bg-white rounded-xl p-6 md:p-8 shadow-sm">
              <h3 className="text-xl font-semibold mb-4">Your Access Controls</h3>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <Lock className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                  <span className="text-gray-600">Manage access through Google Cloud IAM</span>
                </li>
                <li className="flex items-start">
                  <Lock className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                  <span className="text-gray-600">Revoke access at any time via Google Cloud Console</span>
                </li>
                <li className="flex items-start">
                  <Lock className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                  <span className="text-gray-600">Monitor all access through BigQuery audit logs</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-12 md:py-20">
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-blue-600 rounded-3xl p-6 md:p-12 relative overflow-hidden">
            <div className="absolute top-0 right-0 w-64 h-64 bg-blue-500 rounded-full transform translate-x-1/2 -translate-y-1/2 blur-2xl"></div>
            <div className="absolute bottom-0 left-0 w-64 h-64 bg-blue-700 rounded-full transform -translate-x-1/2 translate-y-1/2 blur-2xl"></div>

            <div className="relative max-w-lg mx-auto text-center">
              <h2 className="text-2xl md:text-3xl font-bold text-white mb-4 md:mb-6">
                Ready to get started?
              </h2>
              <p className="text-blue-100 mb-6 md:mb-8 px-4 sm:px-0">
                Start syncing your GA4 data with BigQuery today and unlock the full potential of your analytics.
              </p>
              <Link 
                to="/pricing"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                className="inline-flex items-center px-6 md:px-8 py-3 md:py-4 bg-white text-blue-600 
                         rounded-xl text-base md:text-lg font-semibold hover:bg-blue-50 transition-all 
                         shadow-lg hover:shadow-xl hover:translate-y-[-1px]"
              >
                Start Backfilling Now
                <ChevronRight className="ml-2" size={20} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default FeaturesPage;